// i18next-extract-mark-ns-start support-training

import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { externalLocaleMapping } from '@utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';

const SupportTrainingPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const {
		loginWithRedirect,
		user
	} = useAuth0();

	const { t, i18n } = useTranslation();
	const locale = externalLocaleMapping(i18n.resolvedLanguage)

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="wide">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					{image && (
						<p>
							<GatsbyImage
								image={image}
								alt="A woman on a video call on her laptop"
							/>
						</p>
					)}
					<Trans i18nKey="content">
						<h3>On-demand Training</h3>
						<p>
							Realis Simulation are delighted to offer customers a
							series of on-demand training videos based on our
							extensive list of tutorial content.
						</p>
						<p>
							Introductory courses are available now,&nbsp;designed
							for&nbsp;customers to learn how to use Realis Simulation
							formerly Ricardo Software products at their&nbsp;own
							pace. Ideal for those new to Realis Simulation formerly
							Ricardo Software or requiring a knowledge refresh.
						</p>
						<p>
							Advanced sources are also available. These courses will
							assume that users&nbsp;have a good grasp of the
							knowledge covered in the basic training videos.
						</p>
						<p>
							All courses are free to access and are available via our
							customer support portal by clicking on the link below.
						</p>
						<p>
							<Button to={`https://support.realis-simulation.com/${locale}/support/solutions/articles/60000696917-on-demand-training`}>
								On-demand tutoring
							</Button>
						</p>
						<p>
							Dates for our&nbsp;upcoming live Q&amp;A sessions can
							also be found via the customer support portal. New
							content is added regularly, please check for the latest
							videos.
						</p>
					</Trans>
						{
						    !user &&
						    <Trans i18nKey="content_3">
						        <p>
							        <strong>
								        <em>
									        If you an existing customer and do not have an
									        RS Account, then please register by clicking on
									        the link below. Registration is quick and easy.
								        </em>
							        </strong>
						        </p>
						    </Trans>
						}
						{
						    !user &&
						    <p>
							    <button className="button" onClick={() => loginWithRedirect({
								    authorizationParams: {
									    screen_hint: 'signup',
									    ui_locales: i18n.resolvedLanguage,
									    mode: 'signUp',
								    }
							    })}>{t("_Signup") /* i18next-extract-disable-line */}</button>
						    </p>
					    }
					<Trans i18nKey="content_1">
						<h3>
							<span>Bespoke Training</span>
						</h3>
						<p>
							Our introductory and advanced training sessions cover
							set topics and materials. If you are interested in
							customized training for your team, please contact us at{' '}
							<a href="mailto:support@realis-simulation.com?subject=Customized%20training%20enquiry">
								support@realis-simulation.com
							</a>{' '}
							or complete the form on this page. We would be happy to
							discuss your specific needs.
						</p>
						<p>
							All courses will be&nbsp;led by a Realis Simulation
							formerly Ricardo Software application engineer. Our
							experts are prepared with the latest product
							advancements,&nbsp;release information and use cases to
							accelerate the engineering process and know-how of
							customer applications.
						</p>
						<p>Prices starting from $2500 per day.</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget>
						<ContactForm name="contact" title={t("_GetInTouch")} />
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default SupportTrainingPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-training", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/singlehero.png" }) {
			...imageBreaker
		}
		image: file(relativePath: { eq: "content/training.jpg" }) {
			...imageStandard
		}
	}
`;
